
import './App.css';
// import './assets/fonts/DMM.ttf'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import BrowserRouter as Router

// import Home from './Pages/Home';
// import About from './Pages/About';
import Layout from './pages/Layout';

function App() {
  return (
    <div className="App">
     <Layout/>
    </div>
  );
}

export default App;